// @ts-nocheck
import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AnimatePresence, m } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';

import { FabButtonAnimate, FabButtonAnimateOutlined } from '@/components/animate';
import useResponsive from '@/hooks/useResponsive';
import { registerCallEvent } from '@/layouts/main/constants';
import { PATH_PAGE99, PATH_SCHEDULE_CALL, PATH_TALK_ADVISOR_MAIN } from '@/routes/paths';
import { useLayoutStore } from '@/stores/layout/layout.store';
import { TitleH6, TitleTextH3 } from '@/theme/OwnGlobalStylesComponents/StyleText';
import { mapEvent } from '@/utils/goggleAnalytics/gtag';

import { productByCountry } from './constants';
import {
  ButtonContainer,
  ButtonsContainer,
  ContainerGeneralHomeHero,
  ImageContain,
  InfoContain,
  Leyend,
  P,
  ProductButton,
  ProductButtonsContainer,
  TextContain,
} from './styles';

const fadeInOutVariants = {
  fadeIn: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  fadeOut: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const HomeHero = () => {
  const { t } = useTranslation();
  const [imageIndex, setImageIndex] = useState<number>(0);
  const isDesktop = useResponsive('up', 'md');
  const isTablet = useResponsive('between', 'md', 'lg');
  const country = useLayoutStore((state) => state.country);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(handleChangeImageIndex, 6000);

    return () => {
      if (intervalRef.current !== null) clearInterval(intervalRef.current);
    };
  }, [imageIndex]);

  const handleChangeImageIndex = (index: number = imageIndex + 1) => {
    if (index < productByCountry[country].length) {
      setImageIndex(index);
    } else {
      setImageIndex(0);
    }
  };

  const activeProduct = useMemo(() => productByCountry[country][imageIndex], [imageIndex]);
  const responsive = isTablet ? '80%' : isDesktop ? '60.41%' : '100%';
  return (
    <ContainerGeneralHomeHero>
      <AnimatePresence mode="popLayout">
        <m.div
          key={imageIndex}
          variants={fadeInOutVariants}
          initial="fadeOut"
          animate="fadeIn"
          exit="fadeOut"
        >
          <InfoContain>
            <ImageContain>
              <Image
                src={isDesktop ? activeProduct.imageDesk : activeProduct.imageResp}
                style={{ margin: isDesktop ? '0 0 0 auto' : '0 auto', height: 'auto' }}
                width={isDesktop ? 580 : 428}
                loading="eager"
              />
              <P>0{imageIndex + 1}</P>
            </ImageContain>

            <TextContain>
              {activeProduct.logo !== undefined ? (
                <Box>
                  {country === 'CL' && (
                    <Leyend>
                      <strong>{activeProduct?.leyend}</strong>
                    </Leyend>
                  )}
                  <Image
                    src={`/assets/icons/home/productLogos/${activeProduct.logo}`}
                    width={83}
                    height={33}
                  />
                </Box>
              ) : (
                <Divider sx={{ width: '4.43rem', bgcolor: '#93C155', mb: '1.43rem' }} />
              )}
              <TitleTextH3 maxWidth={responsive} mb={isDesktop ? '1.46rem' : '0.7rem'}>
                <strong>
                  {country === 'CL' && activeProduct.titleCl
                    ? activeProduct.titleCl
                    : activeProduct.title}
                </strong>
              </TitleTextH3>

              <Trans>
                <TitleH6 maxWidth={responsive}>
                  {country === 'CL' && activeProduct.descriptionCL
                    ? activeProduct.descriptionCL
                    : activeProduct.description}
                </TitleH6>
              </Trans>

              <ButtonContainer>
                {!['CL', 'PE'].includes(country) && (
                  <FabButtonAnimateOutlined
                    variant="outlinedExtended"
                    size={isDesktop ? 'medium' : 'small'}
                    color="primary"
                    // href={PATH_SCHEDULE_CALL[country]}
                    href={'/sales'}
                    // target="_blank"
                    onClick={() => mapEvent(registerCallEvent)}
                  >
                    {country === 'MX' ? t('COMMON.ACTIONS.CALL_3') : t('COMMON.ACTIONS.CALL')}
                  </FabButtonAnimateOutlined>
                )}
                {/*
                {imageIndex === 0 && (
                  <FabButtonAnimate
                    variant="extended"
                    size={isDesktop ? 'medium' : 'small'}
                    color="primary"
                    target="_blank"
                    href={PATH_TALK_ADVISOR_MAIN}
                  >
                    {t('COMMON.ACTIONS.REGISTER')}
                  </FabButtonAnimate>
                )}
                */}
              </ButtonContainer>
              {activeProduct.key === 'envios99_CL' && ['CL'].includes(country) && (
                <ButtonContainer>
                  <FabButtonAnimate
                    variant="extended"
                    size={isDesktop ? 'medium' : 'small'}
                    color="primary"
                    target="_blank"
                    href={PATH_PAGE99.product.delivery}
                  >
                    <strong>{t('COMMON.ACTIONS.ENVIOS_CL')}</strong>
                  </FabButtonAnimate>
                </ButtonContainer>
              )}
              {activeProduct.key === 'tailor99_CL' && ['CL'].includes(country) && (
                <ButtonContainer>
                  <FabButtonAnimate
                    variant="extended"
                    size={isDesktop ? 'medium' : 'small'}
                    color="primary"
                    target="_blank"
                    href={PATH_PAGE99.product.tailor}
                  >
                    <strong>{t('COMMON.ACTIONS.TAILOR99_CL')}</strong>
                  </FabButtonAnimate>
                </ButtonContainer>
              )}
            </TextContain>
          </InfoContain>
        </m.div>
      </AnimatePresence>

      <ButtonsContainer>
        <Typography mb={2} color={'#12344A'}>
          <strong>{t('SLIDER.TITLE')}</strong>
        </Typography>

        <ProductButtonsContainer>
          {productByCountry[country].map((product, i) => {
            return (
              product.logo?.length > 0 && (
                <ProductButton
                  variant={i === imageIndex ? 'contained' : 'outlined'}
                  key={product.logo}
                  imageIndex={i !== imageIndex}
                  onClick={() => handleChangeImageIndex(i)}
                >
                  <Image
                    src={`/assets/icons/home/productLogos/${
                      i === imageIndex ? product.logoActive : product.logo
                    }`}
                    width={83}
                    height={33}
                  />
                </ProductButton>
              )
            );
          })}
        </ProductButtonsContainer>
      </ButtonsContainer>
    </ContainerGeneralHomeHero>
  );
};

export default HomeHero;
